<template>
  <view-item title="配件入库明细">
    <template #operation>
      <en-button v-if="['purchase', 'refund', 'other'].includes(tabs.active)" @click="operation.total.click" type="primary">查看总计</en-button>
      <en-button @click="operation.export.click">导出</en-button>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="采购入库" name="purchase" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="PUCIDFD"
                    :height="height"
                    :data="purchase.data"
                    :method="purchase.get"
                    :loading="purchase.loading"
                    pagination
                    :paging="purchase.paging"
                    show-summary
                    :summary="purchase.summary.data"
                    :config="purchase.config"
                  >
                    <template #GOODS_SPECIFICATION_WEIGHT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsQueryDto'] }">
                      {{ `${row.weight}${row.unit}` }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="采购退货" name="refund" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="PUCRDFD"
                    :height="height"
                    :data="refund.data"
                    :method="refund.get"
                    :loading="refund.loading"
                    pagination
                    :paging="refund.paging"
                    show-summary
                    :summary="refund.summary.data"
                    :config="refund.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="其他进货" name="other" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="PUCODFD"
                    :height="height"
                    :data="other.data"
                    :method="other.get"
                    :loading="other.loading"
                    pagination
                    :paging="other.paging"
                    show-summary
                    :summary="other.summary.data"
                    :config="other.config"
                  >
                    <template #GOODS_SPECIFICATION_WEIGHT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsQueryDto'] }">
                      {{ `${row.weight}${row.unit}` }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="移库入库" name="transfer" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="STFIGFD"
                    :height="height"
                    :data="transfer.data"
                    :method="transfer.get"
                    :loading="transfer.loading"
                    pagination
                    :paging="transfer.paging"
                    show-summary
                    :summary="transfer.summary.data"
                    :config="transfer.config"
                  >
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <en-drawer v-model="purchaseDetail.visible" title="查看总计" size="20%" v-if="tabs.active === 'purchase'">
    <div v-for="item in purchaseDetail.table.data" class="flex flex-col gap-7">
      <div class="flex flex-col gap-4">
        <div style="color: #4c58d9ff">含税总额:{{ formatMoney(item.totalAmount) }}</div>
        <div style="color: #4c58d9ff">不含税总额:{{ formatMoney(item.totalAmountBeforeTaxRate) }}</div>
        <div style="color: #4c58d9ff">数量:{{ formatMoney(item.totalCount) }}</div>
      </div>
    </div>
    <template #footer>
      <en-button type="primary" @click="purchaseDetail.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="purchaseDetail.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>

  <en-drawer v-model="refundDetail.visible" title="查看总计" size="20%" v-if="tabs.active === 'refund'">
    <div v-for="item in refundDetail.table.data" class="flex flex-col gap-7">
      <div class="flex flex-col gap-4">
        <div style="color: #4c58d9ff">数量:{{ formatMoney(item.totalCount) }}</div>
        <div style="color: #4c58d9ff">含税总额:{{ formatMoney(item.totalAmount) }}</div>
        <div style="color: #4c58d9ff">不含税总额:{{ formatMoney(item.totalAmountBeforeTaxRate) }}</div>
      </div>
    </div>
    <template #footer>
      <en-button type="primary" @click="refundDetail.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="refundDetail.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>

  <en-drawer v-model="otherDetail.visible" title="查看总计" size="20%" v-if="tabs.active === 'other'">
    <div v-for="item in otherDetail.table.data" class="flex flex-col gap-7">
      <div class="flex flex-col gap-4">
        <div style="color: #4c58d9ff">数量:{{ formatMoney(item.totalCount) }}</div>
        <div style="color: #4c58d9ff">含税总额:{{ formatMoney(item.totalAmount) }}</div>
        <div style="color: #4c58d9ff">不含税总额:{{ formatMoney(item.totalAmountBeforeTaxRate) }}</div>
      </div>
    </div>
    <template #footer>
      <en-button type="primary" @click="otherDetail.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="otherDetail.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        children: {
          total: {
            async click() {
              if (this.tabs.active === 'purchase') {
                this.purchaseDetail.visible = true
              } else if (this.tabs.active === 'refund') {
                this.refundDetail.visible = true
              } else if (this.tabs.active === 'other') {
                this.otherDetail.visible = true
              }
            }
          },
          export: {
            ajax: {
              purchase: {
                action: 'GET /enocloud/stock/in/export/goods',
                params(params) {
                  params.payload = this.purchase.$ajaxParams.payload
                }
              },
              refund: {
                action: 'GET /enocloud/purchase/export/goods',
                params(params) {
                  params.payload = this.refund.$ajaxParams.payload
                }
              },
              other: {
                action: 'GET /enocloud/purchase/export/goods',
                params(params) {
                  params.payload = this.other.$ajaxParams.payload
                }
              }
            },
            async click() {
              await this.operation.export[this.tabs.active as 'purchase' | 'refund' | 'other']()
              return this.store.openDownload()
            }
          }
        }
      },
      tabs: {
        active: 'purchase'
      },
      purchase: {
        ajax: {
          get: {
            action: 'GET /enocloud/purchase/goods/query',
            summary: 'GET /enocloud/purchase/goods/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { typeCode: 'PUC' }
            }
          }
        },
        config: {
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'oem'
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          STOCK_IN_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'stockInSerialNo'
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          GOODS_BARCODE: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.barcode'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.model'
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgressCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['STLMTPRG']
                    }
                  },
                  allowCreate: true,
                  remote: true,
                  multiple: true,
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          PURCHASE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'supplierId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, type: 'B', rsexp: 'id,name' })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          GOODS_VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.primaryVehicleSpec'
              }
            }
          },
          PURCHASE_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'purchaseComment'
              }
            }
          },
          BUSINESS_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          INVOICE_TYPE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiceTypeName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['INVCTP'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          }
        }
      },
      refund: {
        ajax: {
          get: {
            action: 'GET /enocloud/purchase/goods/query',
            summary: 'GET /enocloud/purchase/goods/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { typeCode: 'PUCR' }
            }
          }
        },
        config: {
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'supplierId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, type: 'B', rsexp: 'id,name' })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          STOCK_IN_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'stockInSerialNo'
              }
            }
          },
          GOODS_BARCODE: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.barcode'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.model'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'oem'
              }
            }
          },
          BUSINESS_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['businessStartDate', 'businessEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          GOODS_VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsVehicleSpec'
              }
            }
          },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgressCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['STLMTPRG'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          REFERENCED_PURCHASE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'purchaseSerialNo'
              }
            }
          },
          PURCHASE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },

          OTHER_TYPE: {
            header: {
              filter: {
                type: 'text',
                field: 'otherType'
              }
            }
          },
          PURCHASE_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'purchaseComment'
              }
            }
          },
          DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: '' }
                }
              }
            }
          },
          INVOICE_TYPE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiceTypeName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => {
                      params.paths = ['INVCTP']
                    }
                  },
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          }
        }
      },
      other: {
        ajax: {
          get: {
            action: 'GET /enocloud/purchase/goods/query',
            summary: 'GET /enocloud/purchase/goods/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { typeCode: 'OTH' }
            }
          }
        },
        config: {
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'supplierId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, type: 'B', rsexp: 'id,name', pagingEnabled: false })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          STOCK_IN_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'stockInSerialNo'
              }
            }
          },
          GOODS_BARCODE: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.barcode'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.model'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'oem'
              }
            }
          },
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          PURCHASE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          PURCHASE_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'purchaseComment'
              }
            }
          },
          DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user'
                  },
                  filterable: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: '' }
                }
              }
            }
          }
        }
      },
      transfer: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/transfer/in/goods/query',
            summary: 'GET /enocloud/stock/transfer/in/goods/summary',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'oem'
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          GOODS_BARCODE: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.barcode'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.model'
              }
            }
          },
          FROM_WAREHOUSE: {
            header: {
              filter: {
                type: 'select',
                field: 'fromWarehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          TO_WAREHOUSE: {
            header: {
              filter: {
                type: 'select',
                field: 'toWarehouseId',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          AUDIT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startAuditDate', 'endAuditDate'],
                props: { type: 'daterange' }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          }
        },
        BRANCH: {
          header: {
            header: { filter: { type: 'text', field: 'branchName' } }
          }
        },
        COMMENT: {
          header: {
            filter: {
              type: 'text',
              field: 'comment'
            }
          }
        }
      },
      purchaseDetail: {
        visible: false,
        ok: '1',
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/stock/in/goods/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = { typeCode: 'PUC' }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.purchaseDetail.visible = false
              }
            },
            confirm: {
              click() {
                this.purchaseDetail.visible = false
              }
            }
          }
        }
      },
      refundDetail: {
        visible: false,
        ok: '2',
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/stock/in/goods/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = { typeCode: 'PUCR' }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.refundDetail.visible = false
              }
            },
            confirm: {
              click() {
                this.refundDetail.visible = false
              }
            }
          }
        }
      },
      otherDetail: {
        visible: false,
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/stock/in/goods/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = { typeCode: 'OTH' }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.otherDetail.visible = false
              }
            },
            confirm: {
              click() {
                this.otherDetail.visible = false
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.purchase.get()
    this.refund.get()
    this.other.get()
    this.transfer.get()
    this.purchaseDetail.table.get()
    this.refundDetail.table.get()
    this.otherDetail.table.get()
  }
})
</script>
